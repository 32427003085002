import React from 'react';
import './App.css';
import {RouterProvider} from "react-router";
import {createBrowserRouter} from "react-router-dom";
import {LogoPage} from "./logo-page";
import { RedirectPage } from "./redirect-page";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LogoPage />
    },
    {
      path: '/claim/*',
      element: <RedirectPage />
    },
    {
      path: '/download/*',
      element: <RedirectPage />
    }
  ]);

  return <RouterProvider router={router} />
}

export default App;
