import { useLocation } from "react-router";

export const RedirectPage = () => {
    const location = useLocation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems:"center", flexDirection: 'column', width: '100%', gap: 10, paddingTop: 30}}>
            <div>You will be redirected to application. If it doesnt work please download application and click link again</div>
            <div><a href="https://apps.apple.com/us/app/lvsports-ai/id6446373986" target={'_blank'}><img width={200} src={'/download-appstore.png'} alt={'Download'}/></a></div>
            <div>If you want to refresh <a href={window.location.href}>Click Here</a></div>
        </div>
    )
}